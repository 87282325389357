var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("create-content-card", {
    ref: "contentField",
    attrs: {
      rules: [() => _vm.listNotEmpty(_vm.selected, "Contents")],
      loading: _vm.loading,
    },
    on: { save: _vm.progress, cancel: _vm.exit },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [
            _c(
              "span",
              {
                staticClass:
                  "ft-bold xl:text-2xl lg:text-2xl md:text-2xl sm:text-2xl",
              },
              [_vm._v(_vm._s(_vm.edit ? "Edit" : "Create") + " Sections")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "controls",
        fn: function () {
          return [
            _c(
              "v-row",
              { attrs: { "no-gutters": "" } },
              [
                _c(
                  "v-col",
                  { attrs: { cols: _vm.cols.controls } },
                  [
                    _c(
                      "v-form",
                      { ref: "nameField" },
                      [
                        _c("v-text-field", {
                          attrs: {
                            outlined: "",
                            counter: "100",
                            label: "Section name",
                            placeholder: "Enter your section name",
                            rules: [
                              _vm.required,
                              (v) =>
                                _vm.minLength(v?.trim(), 5, `Section name`),
                              (v) =>
                                _vm.maxLength(v?.trim(), 100, `Section name`),
                              (v) =>
                                v === undefined ||
                                v === null ||
                                v.toLowerCase() !== "unassigned" ||
                                "Name can not be the word 'Unassigned' ",
                            ],
                          },
                          model: {
                            value: _vm.title,
                            callback: function ($$v) {
                              _vm.title =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "title",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "contents",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "pa-3" },
              _vm._l(_vm.contents, function (item, index) {
                return _c("section-item-card", {
                  key: index,
                  attrs: { item: item },
                  model: {
                    value: _vm.selected,
                    callback: function ($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected",
                  },
                })
              }),
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }