<template>
  <!-- min-height="200" -->
  <v-sheet
     style="min-height: 75vh;"
     class="rounded-lg"
    :class="{ 'd-flex align-center justify-center': loading }"
  >
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <no-content-component
      v-else-if="noContentCondition"
      message="No course modules designed"
    ></no-content-component>
    <v-container v-else :class="classes.container">
      <course-sections 
        :sections="sections"
        @onSelect="selectSection"
      />
      <div class="d-flex flex-grow-1">
        <div
          class="d-flex align-center justify-space-between"
          style="width: 100px; height: max-content"
        >
          <v-btn
            v-if="!fromStudentDashboard"
            :class="{ 'controls-faded': unassignedSectionCondition }"
            icon
            @click="toggleSectionEditDialog(selected)"
          >
            <v-progress-circular
              v-if="saving"
              indeterminate
              color="primary"
              size="20"
            ></v-progress-circular>
            <img v-else :src="icons.edit" alt="edit-icon" />
          </v-btn>
          <v-btn
            v-if="!fromStudentDashboard"
            :class="{ 'controls-faded': unassignedSectionCondition }"
            icon
            @click="setupDelete(selected)"
          >
            <v-progress-circular
              v-if="deleting"
              indeterminate
              color="orange"
              size="20"
            ></v-progress-circular>
            <img v-else :src="icons.delete" alt="delete-icon" />
          </v-btn>
        </div>
      </div>
      <div
        v-if="!fromStudentDashboard"
        style="max-height: 45vh"
        class="mt-sm-4 mt-xs-2 overflow-x-hidden overflow-y-auto"
      >
        <course-organization-expansion-panel
          :selected="selected"
          :course-id="courseId"
          :owner-id="ownerId"
          @update="$emit(`update`)"
        ></course-organization-expansion-panel>
      </div>
      <div
        v-else
        style="max-height: 55vh"
        class="mt-sm-4 mt-xs-2 overflow-x-hidden overflow-y-auto"
      >
        <course-organization-expansion-panel
          :selected="selected"
          :course-id="courseId"
          :owner-id="ownerId"
        ></course-organization-expansion-panel>
      </div>
    </v-container>
    <v-dialog
      v-if="!fromStudentDashboard"
      v-model="editSectionParams.dialog"
      width="unset"
    >
      <create-section-panel
        v-model="outgoing"
        v-bind="editSectionParams.attrs"
        v-on="editSectionParams.on"
      ></create-section-panel>
    </v-dialog>
    <dialog-delete
      v-if="!fromStudentDashboard"
      v-bind="deleteDialogParams.attrs"
      v-on="deleteDialogParams.on"
    ></dialog-delete>
  </v-sheet>
</template>

<script>
import NoContentComponent from "/global/components/NoContentComponent.vue";
import CourseOrganizationExpansionPanel from "#ecf/course-design/components/CourseOrganization/CourseOrganizationExpansionPanel.vue";
import DialogDelete from "@ecf/global/components/DialogDelete.vue";
import { CourseDesignUtils } from "#ecf/course-design/mixins";
import CreateSectionPanel from "#ecf/course-design/components/CreateSection/CreateSectionPanel.vue";
import CourseSections from "./CourseSections.vue";

export default {
  name: "CourseOrganizationComponent",
  components: {
    CreateSectionPanel,
    NoContentComponent,
    CourseOrganizationExpansionPanel,
    DialogDelete,
    CourseSections
  },
  mixins: [CourseDesignUtils],
  props: ["courseId", "ownerId", "fromStudentDashboard"],
  data() {
    return {
      loading: true,
      saving: false,
      deleting: false,
      selected: undefined,
      editSectionParams: {
        id: undefined,
        dialog: false,
        attrs: {
          courseId: this.courseId,
          loading: this.saving,
          edit: true
        },
        on: {
          save: this.editSection,
          update: this.updateSection,
          close: this.toggleSectionEditDialog
        }
      },
      deleteDialogParams: {
        attrs: {
          value: false,
          item: {},
          itemText: ""
        },
        on: {
          delete: () => {},
          cancel: () => {}
        }
      },
      outgoing: {}
    };
  },
  computed: {
    sections() {
      if (this.fromStudentDashboard) {
        return this.$store.state.studentCourseDesignDashboard.courseSections[
          this.$ielts_course_id
        ];
      } else {
        return this.$store.getters["design/sections"][this.courseId];
      }
    },
    noContentCondition() {
      return this.sections?.length === 0;
    },
    unassignedSectionCondition() {
      if (this.selected === undefined) return false;
      return this.sections[this.selected].section_id === undefined;
    },
    icons() {
      return {
        edit: require("#ecf/course-design/assessts/icon_section_edit.svg"),
        delete: require("#ecf/course-design/assessts/icon_delete.svg")
      };
    },
    classes() {
      return {
        container: `pa-${this.breakPointValues(3, 3, 6, 6)}`,
        slideItem: {
          outer: {
            true: "primary white--text",
            false: "bg-white border border-solid border-primary primary--text"
          },
          inner: {
            true: "white primary--text",
            false: "primary white--text"
          }
        }
      };
    }
  },
  watch: {
    "editSectionParams.dialog": {
      handler(value) {
        if (value) {
          this.outgoing = this.sections[this.selected];
        }
      }
    }
  },
  async created() {
    if (this.fromStudentDashboard) {
      await this.retriveStudentsCourseDesign();
    } else {
      await this.retrieveSectionsAndUnassigned();
    }
  },
  methods: {
    async retriveStudentsCourseDesign() {
      try {
        this.loading = true;
        let courseId = this.$ielts_course_id;
        let studentId = this.$store.state.user.user.user_id;
        await this.$store.dispatch(
          "studentCourseDesignDashboard/getCourseDesign",
          {
            courseId,
            studentId
          }
        );
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.loading = false;
      }
    },
    async retrieveSectionsAndUnassigned() {
      try {
        this.loading = true;
        await this.$store.dispatch("design/getCourseSections", {
          courseId: this.courseId,
          ownerId: this.ownerId
        });
        await this.$store.dispatch("design/getUnassignedModules", {
          courseId: this.courseId,
          ownerId: this.ownerId
        });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.loading = false;
      }
    },
    toggleSectionEditDialog(index) {
      this.editSectionParams.id = this.editSectionParams.dialog
        ? undefined
        : index;
      this.editSectionParams.dialog = !this.editSectionParams.dialog;
    },
    updateSection(payload) {
      this.editSectionParams.outgoing = payload;
    },
    async editSection() {
      try {
        this.saving = true;
        await this.$store.dispatch("design/editSection", {
          id: this.sections[this.editSectionParams.id].section_id,
          title: this.outgoing.title,
          contents: this.outgoing.contents,
          courseId: this.courseId,
          ownerId: this.ownerId
        });
        this.$root.$emit("alert", [
          "Success",
          "Section has been edited successfully"
        ]);
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.saving = false;
        this.toggleSectionEditDialog();
        this.$emit("update");
      }
    },
    setupDelete(value) {
      this.deleteDialogParams.attrs.value = true;
      this.deleteDialogParams.attrs.item = this.sections[value];
      this.deleteDialogParams.attrs.itemText = "Section";
      this.deleteDialogParams.on.delete = this.deleteSection;
      this.deleteDialogParams.on.cancel = this.resetDelete;
    },
    resetDelete() {
      this.deleteDialogParams.attrs.value = false;
      this.deleteDialogParams.attrs.item = {};
      this.deleteDialogParams.attrs.itemText = "";
      this.deleteDialogParams.on.delete = () => {};
      this.deleteDialogParams.on.cancel = () => {};
    },
    async deleteSection(payload) {
      try {
        this.deleting = true;
        await this.$store.dispatch("design/removeSection", {
          sectionId: payload.section_id,
          courseId: this.courseId,
          ownerId: this.ownerId
        });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.resetDelete();
        this.deleting = false;
        this.$emit("update");
      }
    },
    selectSection(value) {
      this.selected = value
    }
  }
};
</script>

<style lang="scss" scoped>
.controls-faded {
  opacity: 0.25;
  pointer-events: none;
}
</style>
