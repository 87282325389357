var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass:
        "border border-solid border-primary pa-2 mb-3 mr-3 d-flex align-center",
      attrs: { flat: "" },
    },
    [
      _c(
        "v-card-actions",
        { staticClass: "ma-0 pa-0 pl-3" },
        [
          _c("v-simple-checkbox", {
            attrs: { color: "primary", value: _vm.isSelected },
            on: { click: _vm.changeValue },
          }),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "ma-0 pa-0 pl-3 ft-bold dark--text xl:text-lg" },
        [_vm._v(_vm._s(_vm.item.title))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }