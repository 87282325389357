<template>
  <v-card
      flat
      class="border border-solid border-primary pa-2 mb-3 mr-3 d-flex align-center"
  >
    <v-card-actions class="ma-0 pa-0 pl-3">
      <v-simple-checkbox color="primary" :value="isSelected" @click="changeValue"></v-simple-checkbox>
    </v-card-actions>
    <v-card-text class="ma-0 pa-0 pl-3 ft-bold dark--text xl:text-lg">{{item.title}}</v-card-text>
  </v-card>
</template>
<script>
export default {
  props: ["item", "selected"],
  model: {
    prop: "selected",
    event: "update"
  },
  computed: {
    isSelected() {
      return this.selected.includes(this.item.module_id);
    }
  },
  methods: {
    changeValue() {
      if (this.isSelected) {
        let index = this.selected.indexOf(this.item.module_id);
        this.selected.splice(index, 1);
      } else {
        this.selected.push(this.item.module_id);
      }
    }
  }
}
</script>