<template>
  <create-content-card
    ref="contentField"
    :rules="[()=>listNotEmpty(selected, 'Contents')]"
    :loading="loading"
    @save="progress"
    @cancel="exit"
  >
    <template #title>
      <span class="ft-bold xl:text-2xl lg:text-2xl md:text-2xl sm:text-2xl"
        >{{ edit ? "Edit" : "Create" }} Sections</span
      >
    </template>
    <template #controls>
      <v-row no-gutters>
        <v-col :cols="cols.controls">
          <v-form ref="nameField">
            <v-text-field
              v-model.trim="title"
              outlined
              counter="100"
              label="Section name"
              placeholder="Enter your section name"
              :rules="[
                required,
                v => minLength(v?.trim(), 5, `Section name`),
                v => maxLength(v?.trim(), 100, `Section name`),
                v =>
                  v === undefined ||
                  v === null ||
                  v.toLowerCase() !== 'unassigned' ||
                  'Name can not be the word \'Unassigned\' '
              ]"
            ></v-text-field>
          </v-form>
        </v-col>
      </v-row>
    </template>
    <template #contents>
      <div class="pa-3">
        <section-item-card
          v-for="(item, index) in contents"
          :key="index"
          :item="item"
          v-model="selected"
        ></section-item-card>
      </div>
    </template>
  </create-content-card>
</template>

<script>
import CreateContentCard from "#ecf/course-design/components/CreateContentCard.vue";
import SectionItemCard from "#ecf/course-design/components/CreateSection/SectionItemCard.vue";
import { FieldValidations } from "/global/utils/validations";
import { CourseDesignUtils } from "#ecf/course-design/mixins";
import _ from "lodash";

export default {
  name: "CreateSectionPanel",
  props: ["outgoing", "courseId", "loading", "edit"],
  model: {
    prop: "outgoing",
    event: "update"
  },
  components: { CreateContentCard, SectionItemCard },
  mixins: [FieldValidations, CourseDesignUtils],
  data() {
    return {
      title: undefined,
      selected: []
    };
  },
  computed: {
    cols() {
      return {
        controls: this.breakPointValues("12", "6", "4", "4")
      };
    },
    contents() {
      return this.$store.getters["design/modules"][this.courseId];
    }
  },
  watch: {
    outgoing: {
      deep: true,
      immediate: true,
      handler(value) {
        if (this.edit) {
          this.title = value.title;
          value.contents?.forEach(item => {
            this.selected.push(item);
          });
        }
      }
    },
    "selected.length": {
      handler() {
        this.$refs.contentField.validate();
      }
    },
    loading: {
      handler(curr, prev) {
        if (curr === false && prev === true) {
          this.$refs.nameField.reset();
          this.$refs.contentField.reset();
          this.clearSelectedArray();
        }
      }
    }
  },
  methods: {
    progress() {
      let nameOk = this.$refs.nameField.validate();
      let contentOk = this.$refs.contentField.validate();
      if (nameOk && contentOk) this.exportSection();
    },
    exit() {
      this.$refs.nameField.reset();
      this.$refs.contentField.reset();
      this.$emit("close");
      this.clearSelectedArray();
    },
    clearSelectedArray() {
      this.selected.splice(0, this.selected.length);
    },
    exportSection() {
      this.$emit("update", {
        title: this.title,
        contents: _.cloneDeep(this.selected)
      });
      this.$emit("save");
    }
  }
};
</script>

<style scoped lang="scss"></style>
