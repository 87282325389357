var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-sheet",
    {
      staticClass: "rounded-lg",
      class: { "d-flex align-center justify-center": _vm.loading },
      staticStyle: { "min-height": "75vh" },
    },
    [
      _vm.loading
        ? _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "primary" },
          })
        : _vm.noContentCondition
        ? _c("no-content-component", {
            attrs: { message: "No course modules designed" },
          })
        : _c(
            "v-container",
            { class: _vm.classes.container },
            [
              _c("course-sections", {
                attrs: { sections: _vm.sections },
                on: { onSelect: _vm.selectSection },
              }),
              _c("div", { staticClass: "d-flex flex-grow-1" }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex align-center justify-space-between",
                    staticStyle: { width: "100px", height: "max-content" },
                  },
                  [
                    !_vm.fromStudentDashboard
                      ? _c(
                          "v-btn",
                          {
                            class: {
                              "controls-faded": _vm.unassignedSectionCondition,
                            },
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                return _vm.toggleSectionEditDialog(_vm.selected)
                              },
                            },
                          },
                          [
                            _vm.saving
                              ? _c("v-progress-circular", {
                                  attrs: {
                                    indeterminate: "",
                                    color: "primary",
                                    size: "20",
                                  },
                                })
                              : _c("img", {
                                  attrs: {
                                    src: _vm.icons.edit,
                                    alt: "edit-icon",
                                  },
                                }),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.fromStudentDashboard
                      ? _c(
                          "v-btn",
                          {
                            class: {
                              "controls-faded": _vm.unassignedSectionCondition,
                            },
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                return _vm.setupDelete(_vm.selected)
                              },
                            },
                          },
                          [
                            _vm.deleting
                              ? _c("v-progress-circular", {
                                  attrs: {
                                    indeterminate: "",
                                    color: "orange",
                                    size: "20",
                                  },
                                })
                              : _c("img", {
                                  attrs: {
                                    src: _vm.icons.delete,
                                    alt: "delete-icon",
                                  },
                                }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              !_vm.fromStudentDashboard
                ? _c(
                    "div",
                    {
                      staticClass:
                        "mt-sm-4 mt-xs-2 overflow-x-hidden overflow-y-auto",
                      staticStyle: { "max-height": "45vh" },
                    },
                    [
                      _c("course-organization-expansion-panel", {
                        attrs: {
                          selected: _vm.selected,
                          "course-id": _vm.courseId,
                          "owner-id": _vm.ownerId,
                        },
                        on: {
                          update: function ($event) {
                            return _vm.$emit(`update`)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass:
                        "mt-sm-4 mt-xs-2 overflow-x-hidden overflow-y-auto",
                      staticStyle: { "max-height": "55vh" },
                    },
                    [
                      _c("course-organization-expansion-panel", {
                        attrs: {
                          selected: _vm.selected,
                          "course-id": _vm.courseId,
                          "owner-id": _vm.ownerId,
                        },
                      }),
                    ],
                    1
                  ),
            ],
            1
          ),
      !_vm.fromStudentDashboard
        ? _c(
            "v-dialog",
            {
              attrs: { width: "unset" },
              model: {
                value: _vm.editSectionParams.dialog,
                callback: function ($$v) {
                  _vm.$set(_vm.editSectionParams, "dialog", $$v)
                },
                expression: "editSectionParams.dialog",
              },
            },
            [
              _c(
                "create-section-panel",
                _vm._g(
                  _vm._b(
                    {
                      model: {
                        value: _vm.outgoing,
                        callback: function ($$v) {
                          _vm.outgoing = $$v
                        },
                        expression: "outgoing",
                      },
                    },
                    "create-section-panel",
                    _vm.editSectionParams.attrs,
                    false
                  ),
                  _vm.editSectionParams.on
                )
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.fromStudentDashboard
        ? _c(
            "dialog-delete",
            _vm._g(
              _vm._b({}, "dialog-delete", _vm.deleteDialogParams.attrs, false),
              _vm.deleteDialogParams.on
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }